import React, { useEffect } from 'react'
import { useAuth } from '../utils/AuthContext'
import { PeopleFirst } from '../components/loader'
import { firebaseErrors } from '../utils/firebaseErrors'
import { useRouter } from 'next/router'
import { message } from 'antd'
import * as moment from 'moment'
import { toast } from 'react-toastify'
import SvgComponent from '../components/loader/loader'

export default function VerifyEmailAddress(props) {
  const height = props.height || '100vh'
  const router = useRouter()
  const { signIn, currentUser, loading, signInWithToken } = useAuth()
  useEffect(() => {
    if (router.query.token) {
      let obj = JSON.parse(
        decodeURIComponent(escape(window.atob(router.query.token)))
      )
      let diff = moment().diff(obj.t, 's')
      console.log(diff)
      if (diff < 300) {
        signIn(obj.u, obj.p)
          .then(() => {
            router.replace('/onboarding')
            // window.location.href = 'https://creators.peoplefirst.cc'
          })
          .catch((error) => {
            let { title } = firebaseErrors(error)
            toast.error(title)
          })
      } else {
        window.location.href = process.env.NEXT_PUBLIC_WP_API
      }
    }
    if (router.query.access_token) {
      signInWithToken(router.query.access_token)
        .then((userCredential) => {
          router.replace('/onboarding')
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [router])
  return (
    <div className="pp-loader" style={{ height: height }}>
      <SvgComponent />
    </div>
  )
}
